import React, { useEffect, useState } from 'react';
import PrivateRoute from '../components/auth/protected';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import Banner from '../components/Banner';
import { connect } from 'react-redux';
// import { navigate } from 'gatsby';
const WP_URL = process.env.GATSBY_WP_API;

const Video = ({ profile }) => {
  const [loading, setLoading] = useState(false);
  const [wordpressData, setWordpressData] = useState();
  const fetchVideoPageData = async () => {
    setLoading(true);
    return fetch(`${WP_URL}/wp-json/acf/v3/video_page/882`)
      .then((data) => data.json())
      .then((data) => {
        setWordpressData(data);
        setLoading(false);
        return data;
      })
      .catch((err) => err);
  };
  useEffect(() => {
    fetchVideoPageData();
  }, []);

  const videoPageData = wordpressData
    ? wordpressData.acf.video_section[0]
    : null;

  return loading ? (
    <i className='fas fa-circle-notch fa-spin' />
  ) : (
    <PrivateRoute showLock>
      {profile && !profile.answers ? <Banner /> : null}
      <Layout className='justify-content-end '>
        <SEO title='Video' />
        <div className='video-container'>
          <div className='video-header'>
            <span
              className='video-text'
              dangerouslySetInnerHTML={{
                __html: videoPageData ? videoPageData.header : '',
              }}
            />
          </div>
          <div className='video-body'>
            <div
              className='video-box'
              dangerouslySetInnerHTML={{
                __html: videoPageData && videoPageData.video_source,
              }}
            />
          </div>
          <div className='video-footer'>
            <span
              className='video-text'
              dangerouslySetInnerHTML={{
                __html: videoPageData ? videoPageData.footer : '',
              }}
            />
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  );
};

const mapStateToProps = (state) => ({
  profile: state.login.auth && state.login.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Video);
